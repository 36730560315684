import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import { FormControlLabel, FormGroup, Link, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid/components";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ActionMenu } from "../../components/BasicTable/ActionMenu";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { QuickStatusFilter } from "../../components/QuickStatusFilter";
import { getCurrentUser } from "../../services/auth-service";
import {
  deleteContainer,
  getContainers,
  IContainer,
} from "../../services/container-service";
import { IUser } from "../../services/user-service";
import { IVehicle } from "../../services/vehicle-service";
import { ContainerStatuses, GridActions, GridTolbarLocale, UserRoles } from "../../utils";
import { VehicleDetails } from "../Vehicles/VehicleDetails";
import { ContainerDialog } from "./ContainerDialog";
import { LoadedVehiclesDialog } from "./LoadedVehiclesDialog";
const ContainersActions = {
  ADD: "add-container",
  PREVIEW_VEHICLE: "preview-vehicle",
};
const ContainersGrid = styled.div`
   {
    height: calc(100vh - 150px);
    width: 100%;
    .loadedVehicles {
      flex-direction: column;
      justify-content: center !important;
      p {
        width: 100%;
      }
    }
  }
`;
export const Containers = () => {
  const {t} = useTranslation();
  const [muiTableKey, setMuiTableKey] = useState<any>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [onlyMyContainers, setOnlyMyContainers] = useState<boolean>(Boolean(searchParams.get("myVehicles")));
  const navigate = useNavigate();
  const params = useParams();
  const [containers, setContainers] = useState<IContainer[]>([]);
  const [open, setOpen] = React.useState(false);
  const [vehicleToEdit, setVehicleToEdit] = React.useState<string | undefined>(
    undefined
  );

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [containerToDelete, setContainerToDelete] = useState<
    IContainer | undefined
  >(undefined);
  const [previewVehicles, setPreviewVehicles] = useState<IVehicle[] | null>(
    null
  );
  const handleStatusClick = (status: string) => {
    if (status === activeStatus) {
      // 👇️ delete each query param
      searchParams.delete("status");

      // 👇️ update state after
      setSearchParams(searchParams);
      return;
    }
    setSearchParams({ status });
  };
  const user = getCurrentUser();
  const isClient = user?.role === UserRoles.CLIENT;
  const renderVehicles = (vehicles: IVehicle[]) => {
    return vehicles ? (
      <Link
        style={{ cursor: "pointer" }}
        onClick={() => {
          setPreviewVehicles(vehicles);
        }}
      >
        {t("View vehicles")} ({vehicles.length})
      </Link>
    ) : (
      0
    );
  };
  const renderName = (data: GridCellParams) => {
    if (!data.row.isReleased) {
      return <Button color="error">{data.value}</Button>;
    }
    return <Button>{data.value}</Button>;
  };
  const resetFilters = async () => {
    setMuiTableKey(muiTableKey+1)
    navigate("/containers")
  }
  const actionColumn: GridColDef = {
    field: "date",
    sortable: false,
    // flex: 1,
    width: 130,
    headerAlign: "center",
    align: "center",
    headerName: t("Actions"),
    renderCell: (params: GridRenderCellParams<Date>) => (
      <ActionMenu>
        {/* <IconButton
          onClick={() => downloadDocuments(params.row, params.row.name)}
        >
          <FolderIcon fontSize="medium" />
        </IconButton> */}
        <IconButton onClick={() => handleToggleOpenDetails(params.row)}>
          <EditIcon fontSize="medium" />
        </IconButton>
        <IconButton onClick={() => handleDeleteContainer(params.row)}>
          <DeleteForeverIcon color="error" fontSize="medium" />
        </IconButton>
      </ActionMenu>
    ),
  };
  const columns: GridColDef[] = [
    {
      field: "user.nickname",
      headerName: t("Clients"),
      width: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.users
          ?.map((user: IUser) => user.nickname || user.firstName)
          .join(",");
      },
    },
    {
      field: "name",
      headerName: t("Container number"),
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return renderName(params);
      },
    },
    {
      field: "atb",
      headerName: t("ATB"),
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        const { atb } = params.row;
        return atb ? (
            <CheckCircleIcon style={{ cursor: "pointer" }} color="primary" />
        ) : (
            <ErrorIcon color="error" />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => {
        return t(ContainerStatuses[params.value as keyof typeof ContainerStatuses]);
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => {
        return t(ContainerStatuses[params.value as keyof typeof ContainerStatuses]);
      },
    },
    {
      field: "shippingLine",
      align: "left",
      width: 150,
      headerAlign: "left",
      headerName: t("Shipping line"),
      type: "string",
      renderCell: (params: GridRenderCellParams) => {
        return params.value?.name;
      },
    },
    { field: "destination", headerName: t("Destination port"), width: 250, },
    {
      field: "expectedDate",
      headerName: t("Expected date"),
      sortable: true,
      width: 150,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD/MM/YYYY") : "",
    },
    {
      field: "vehicles",
      sortable: false,
      // flex: 1,
      width: 200,
      headerAlign: "center",
      headerName: t("Loaded vehicles"),
      cellClassName: "loadedVehicles",
      renderCell: (params: GridRenderCellParams) => {
        return renderVehicles(params.value);
      },
    },
    {
      field: "createdAt",
      headerName: t("Creation date"),
      sortable: true,
      width: 150,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY");
      },
    },
    ...(user?.role !== UserRoles.CLIENT ? [actionColumn] : []),
  ];
  const mobileColumns: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    { field: "name", headerName: t("Container number"), width: 150 },
    {
      field: "expectedDate",
      headerName: t("Expected date"),
      sortable: true,
      width: 160,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD/MM/YYYY") : "",
    },
    {
      field: "vehicles",
      sortable: false,
      headerAlign: "center",
      width: 200,
      headerName: t("Loaded vehicles"),
      cellClassName: "loadedVehicles",
      renderCell: (params: GridRenderCellParams) => {
        return renderVehicles(params.value);
      },
    },
    {
      field: "createdAt",
      headerName: t("Creation date"),
      sortable: true,
      width: 150,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY");
      },
    },
    ...(user?.role !== UserRoles.CLIENT ? [actionColumn] : []),
  ];
  const handleDeleteContainer = (container: IContainer) => {
    setContainerToDelete(container);
    setDeleteOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      if (containerToDelete) await deleteContainer(containerToDelete.id);
      toast.success(t("Container deleted successfully"));
    } catch (error) {
      console.error(error, "ERROR");
      toast.error(t("Something went wrong"));
    }
    loadContainers();
    setContainerToDelete(undefined);
    setDeleteOpen(false);
  };
  const activeStatus = searchParams.get("status");
  const handleToggleOpenDetails = (container?: IContainer) => {
    navigate(
      !params.containerId
        ? `/containers/edit-container/${container?.id}`
        : "/containers"
    );
  };
  const handleToggleOpen = () => {
    navigate(!params.action ? "/containers/add-container" : "/containers");
  };
  const handleToggleOpenVehicleDetails = () => {
    setVehicleToEdit(undefined);
  };
  const loadContainers = async (searchParams?: URLSearchParams) => {
    const { data: result } = await getContainers(activeStatus, onlyMyContainers);
    setContainers(result.data);
  };
  useEffect(() => {
    if(isClient) {
      return;
    }
    searchParams.set("onlyMyContainers", onlyMyContainers ? "1" : "0");
    setSearchParams(searchParams);
  }, [onlyMyContainers, searchParams, setSearchParams])
  useEffect(() => {
    loadContainers();
  }, [searchParams, onlyMyContainers]);
  useEffect(() => {
    setOpen(params.action === ContainersActions.ADD || !!params.containerId);
  }, [params.action, params.containerId]);
  useEffect(() => {
    if (params.vin) {
      setVehicleToEdit(params.vin);
    }
  }, [params.action, params.vin]);

  const statuses = Object.keys(ContainerStatuses).map((key) => {
    return {
      key,
      name: ContainerStatuses[key],
    };
  });
  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridActions>
        <Typography variant="h4">{t("Containers")}</Typography>
        <div
          style={{
            display: "flex",
            gap: 5,
          }}
        >
        {user?.role !== UserRoles.CLIENT && (
          <Button variant="contained" onClick={handleToggleOpen}>
            {t("Add Container")}
          </Button>
        )}
        <Button variant="contained" onClick={resetFilters}>
          {t("Clear filters")}
        </Button>
        </div>
        {open && (
          <ContainerDialog
            submitCallback={loadContainers}
            handleToggleOpen={handleToggleOpen}
          />
        )}
        {vehicleToEdit && (
          <VehicleDetails
            vehicleId={vehicleToEdit}
            handleToggleOpen={handleToggleOpenVehicleDetails}
          />
        )}
      </GridActions>
      <ConfirmDialog
        handleConfirm={handleConfirmDelete}
        handleClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        title={t("Are you sure to delete this container?")}
      >
        {t("Deleting container")} <strong>{containerToDelete?.name}</strong>
      </ConfirmDialog>
      {previewVehicles && (
        <LoadedVehiclesDialog
          vehicles={previewVehicles}
          //submitCallback={loadVehicles}
          handleToggleOpen={() => {
            setPreviewVehicles(null);
          }}
        />
      )}
      <GridActions>
        <QuickStatusFilter
          activeStatus={activeStatus}
          onClick={handleStatusClick}
          statuses={statuses}
        />
      </GridActions>
      {!isClient && (
          <FormGroup>
            <FormControlLabel control={<Switch onChange={(event) => {
              setOnlyMyContainers(event.target.checked)
            }} checked={onlyMyContainers} />} label={t("Only my containers")} />
          </FormGroup>
      )}
      <ContainersGrid>
        <DataGrid
        key={muiTableKey}
        localeText={GridTolbarLocale(t)}
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "expectedDate", sort: "desc" }],
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns createdAt, the other columns will remain visible
                createdAt: isMobile,
              },
            },
          }}
          disableSelectionOnClick
          rows={containers}
          columns={isMobile ? mobileColumns : columns}
          pageSize={15}
          rowsPerPageOptions={[5, 10, 20, 50]}
          checkboxSelection
        />
      </ContainersGrid>
    </Box>
  );
};
