import axiosIntance from "../axiosInstance";
import { ContainerStatuses } from "../utils";
import { IShipper } from "./shipper-service";
import { IShippingLine } from "./shipping-line-service";
import { IUser } from "./user-service";
import { IVehicle } from "./vehicle-service";
export interface IContainer {
    id: string;
    name: string,
    users?: IUser[],
    destination?: string,
    portOfDeparture?: string,
    shipper?: IShipper,
    status?: keyof typeof ContainerStatuses;
    containerNumber?: string;
    shippingLine?: IShippingLine;
    shippingLineId: string;
    shipperId: string;
    expectedDate?: string | undefined;
    notes?: string;
    atb?: boolean;
    adminNotes?: string;
    warehouseId?: string;
    isReleased?: boolean;
    atbNumber?: string;
    warehouse?: IUser;
    documents?: string[];
    vehicles?: IVehicle[];
    vehicleIds?: string[],
  }
interface IContainersResponse {
    count: number,
    data: IContainer[]
}
export const getContainers = (status?: string | null, myContainers?: boolean) => {
    return axiosIntance.get<IContainersResponse>(`/containers/`, {
        params: {
          status,
          myContainers: myContainers || undefined
        }
    });
};
export const getContainer = (containerId:string) => {
    return axiosIntance.get<IContainer>(`/containers/${containerId}`);
};
export const createContainer = (values: IContainer) => {
    return axiosIntance.post<IContainer>(`/containers/`, values);
};
export const updateContainer = (containerId: string, values: IContainer) => {
    return axiosIntance.put<IContainer>(`/containers/${containerId}`, values);
};
export const deleteContainer = (containerId: string) => {
    return axiosIntance.delete<IContainer>(`/containers/${containerId}`);
};