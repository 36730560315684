import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Form, Formik } from "formik";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { AuctionSelect } from "../../components/FormElements/AuctionSelect";
import { MultipleUserSelect } from "../../components/FormElements/MultipleUserSelect";
import { ShipperSelect } from "../../components/FormElements/ShipperSelect";
import { ShippingLineSelect } from "../../components/FormElements/ShippingLineSelect";
import { TextFieldWrapper } from "../../components/FormElements/TextFieldWrapper";
import { UserSelect } from "../../components/FormElements/UserSelect";
import { VehicleSelect } from "../../components/FormElements/VehicleSelect";
import { getCurrentUser } from "../../services/auth-service";
import { IContainer } from "../../services/container-service";
import { ContainerStatuses, UserRoles } from "../../utils";

interface ContainerFormProps {
  containerData?: IContainer;
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
}
export const ContainerForm: React.FC<ContainerFormProps> = ({
  formRef,
  containerData,
  handleSubmit,
}) => {
  const {t} = useTranslation();
  const user = getCurrentUser();
  const isClient = user?.role === UserRoles.CLIENT;
  let validationSchema = object({
    name: string().required("Container number is required"),
    expectedDate: string().when("status", ([status], sch) => {
      return status !== "LOADED"
        ? sch.required("Expected date is required")
        : sch.notRequired();
    }),
  });
  const containerValues = {
    expectedDate: null,
    status: "LOADED",
  } as unknown as IContainer;
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={containerData || containerValues}
      onSubmit={async (values: any) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
      }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextFieldWrapper
                required
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="name"
                name="name"
                label={t("Container number")}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MultipleUserSelect
                label={t("Customers")}
                required
                role={[UserRoles.ADMIN, UserRoles.CLIENT]}
                handleChange={setFieldValue}
                values={values?.users ? values.users : undefined}
                error={Boolean(errors.users)}
              />
            </Grid>
            {user?.role === UserRoles.ADMIN && (
              <Grid item xs={12} md={6}>
                <UserSelect
                  required={false}
                  role={[UserRoles.EMPLOYEE]}
                  label={t("Employee")}
                  handleChange={(user) =>
                    setFieldValue("warehouseId", user?.id)
                  }
                  values={values?.warehouse}
                  error={Boolean(errors.warehouseId)}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <AuctionSelect
                value={values.portOfDeparture}
                label={t("Port of loading")}
                handleChange={(port: any) => {
                  setFieldValue("portOfDeparture", port);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AuctionSelect
                value={values.destination}
                label={t("Destination")}
                handleChange={(port: any) => {
                  setFieldValue("destination", port);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ShippingLineSelect
                handleChange={(shippingLine) =>
                  setFieldValue("shippingLineId", shippingLine?.id)
                }
                value={containerData?.shippingLine}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="status"
                name="status"
                label="Status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {Object.keys(ContainerStatuses).map((key) => {
                  return (
                    <MenuItem id={key} key={key} value={key}>
                      {t(ContainerStatuses[key])}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      transform: "scale(1.2)",
                    }}
                    size="medium"
                    onChange={(event) => {
                      setFieldValue("isReleased", event.target.checked);
                    }}
                    checked={values.isReleased}
                  />
                }
                label={t("Released")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      transform: "scale(1.2)",
                    }}
                    size="medium"
                    onChange={(event) => {
                      setFieldValue("atb", event.target.checked);
                    }}
                    checked={values.atb}
                  />
                }
                label={t("ATB")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ShipperSelect
                handleChange={(shipper) =>
                  setFieldValue("shipperId", shipper?.id)
                }
                value={values?.shipper}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldWrapper
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                id="atbNumber"
                name="atbNumber"
                label="ATB"
                value={values.atbNumber}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6}>
                {!isMobile ? (
                  <DesktopDatePicker
                    label={t("Expected date")}
                    inputFormat="DD/MM/YYYY"
                    value={values.expectedDate}
                    onChange={(value) => {
                      setFieldValue("expectedDate", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required={values.status !== "LOADED"}
                        fullWidth
                        size="small"
                        {...params}
                        helperText={errors.expectedDate}
                        error={Boolean(errors.expectedDate)}
                      />
                    )}
                  />
                ) : (
                  <MobileDatePicker
                    label={t("Expected date")}
                    inputFormat="MM/DD/YYYY"
                    value={values.expectedDate}
                    onChange={(value) => {
                      setFieldValue("expectedDate", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        helperText={errors.expectedDate}
                        required={values.status !== "LOADED"}
                        {...params}
                        error={Boolean(errors.expectedDate)}
                      />
                    )}
                  />
                )}
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={12}>
              <VehicleSelect
                useAsyncOptions
                noContainer
                handleChange={setFieldValue}
                initialValues={values.vehicles ? [...values.vehicles] : []}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFieldWrapper
                multiline
                size="small"
                fullWidth
                id="notes"
                inputProps={{
                  maxLength: 300,
                }}
                name="notes"
                label={t("Notes")}
                value={values.notes}
                onChange={handleChange}
                onBlur={handleBlur}
              ></TextFieldWrapper>
            </Grid>
            {!isClient && (
              <Grid item xs={12} md={12}>
                <TextFieldWrapper
                  multiline
                  size="small"
                  fullWidth
                  id="adminNotes"
                  inputProps={{
                    maxLength: 300,
                  }}
                  name="adminNotes"
                  label={t("Internal notes")}
                  value={values.adminNotes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></TextFieldWrapper>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
